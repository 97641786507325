.subject-selection {
  font-family: Arial, sans-serif;
  max-width: 900px;
  margin: auto;
  padding: 20px;
}

.section-heading {
  font-size: 1.5rem;
  margin: 20px 0 10px;
  color: #555;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.card-container {
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card-container.selected {
  background-color: #fffbe6;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transform: scale(1.03);
}

.card-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card-content {
  padding: 15px;
}

.card-title {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.card-text {
  font-size: 1rem;
  color: #666;
}

.payment-section {
  margin-top: 30px;
}

.payment-options label {
  margin-right: 20px;
  font-size: 1rem;
}

.payment-options input {
  margin-right: 8px;
}

.btn {
  width: 100%;
  padding: 10px;
  font-size: 1.1rem;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.summary {
  margin-top: 30px;
  font-size: 1rem;
}

.subject-selection .row {
  display: flex;
  justify-content: space-between;
}

.section-heading {
  font-size: 1.5rem;
  margin-top: 20px;
}

.card-grid {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.card-container {
  width: calc(33.33% - 10px);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s, background-color 0.3s;
}

.card-container.selected {
  background-color: #ffeb3b;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.summary-panel {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.summary-panel h3 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #333;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 1rem;
}

.total-price {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: right;
}
